import TheCoproductors from '@/views/coproductors/TheCoproductors';

import EditCoproductor from '@/views/coproductors/edit/EditCoproductor';
import CoproductorInvite from '@/views/coproductors/create/NewCoproductor';

import MyCoproductors from '@/views/coproductors/coproductors/TheCoproductors';
import MyCoproductorsInvite from '@/views/coproductors/invites/CoproductorInvites';
import MyCoproductions from '@/views/coproductors/coproductions/TheCoproductions';

import TheCoproductorAccept from '@/views/invitation/TheCoproductorAccept'

export default [
    {
        path: '/coproductors',
        name: 'coproductors',
        component: TheCoproductors,
        meta: {
            requiresAuth: true,
            menuPage: 'coproductors'
        },
        children: [
            {
                path: '/coproductors',
                component: MyCoproductors,
                children: [
                    {
                        path: '/coproductors/create',
                        component: CoproductorInvite,                       
                    }
                ]
            },
            {
                path: '/coproductors/invitations',
                name: 'coproductors-invites',
                component: MyCoproductorsInvite,
                children: [
                    {
                        path: '/coproductors/invitations/create',
                        component: CoproductorInvite,                       
                    }
                ]
            },
            {
                path: '/coproductors/products',
                component: MyCoproductions,
            },
        ]
    },

    {
        path: '/coproductors/:id',
        name: 'coproductors-edit',
        component: EditCoproductor,
        meta: {
            requiresAuth:true,
            menuPage: 'coproductors',
        }
    },

    {
        path: '/invitation/coproductor/:code',
        name: 'coproductorAccept',
        component: TheCoproductorAccept,
        meta:{
            requiresAuth: true,
            menuPage: 'coproductors',
        }
    },
];