<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div>
            <header>
                <font-awesome-icon icon="fa-solid fa-chart-network" style="color: #215eda;"/>
                <span v-if="route.name == 'storeWebhookEdit'" class="ms-2 align-middle">Editar webhook</span>
                <span v-else class="ms-2 align-middle">Novo webhook</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-12 mb-3"
                            label="Nome *"
                            type="text"
                            id="title"
                            v-model="webhook.title"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-12 mb-3"
                            label="URL *"
                            type="text"
                            id="url"
                            v-model="webhook.url"
                            :page="route.name"
                        />
                        <BaseSelect
                            class="col-md-12 col-12"
                            label="Produto *"
                            type="text"
                            id="id_product"
                            placeholder="Todos"
                            :items="props.productsList"
                            v-model="webhook.id_product"
                            :page="route.name"
                        />
                    </div>
                    <h6 class="mt-3">Eventos</h6>
                        <BaseSwitch v-model:checked="webhook.events.abandoned_checkout" label="Abandono de checkout"/>
                        <BaseSwitch v-model:checked="webhook.events.order_created" label="Pedido criado"/>
                        <BaseSwitch v-model:checked="webhook.events.order_paid" label="Pedido pago"/>
                        <BaseSwitch v-model:checked="webhook.events.order_refused" label="Pedido recusado"/>
                        <BaseSwitch v-model:checked="webhook.events.order_canceled" label="Pedido cancelado"/>
                        <BaseSwitch v-model:checked="webhook.events.refunded" label="Pedido devolvido"/>
                        <BaseSwitch v-model:checked="webhook.events.chargeback" label="Chargeback"/>
                        <BaseSwitch v-model:checked="webhook.events.order_tracking" label="Atualização de rastreio"/>
                        <BaseSwitch v-model:checked="webhook.events.order_recurrence" label="Cobrança de recorrência"/>
                        <BaseButton class="btn-primary w-100 mt-4" @click="submit" :disabled="isLoading"> Salvar </BaseButton>
                </form>
            </div>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();

const globalStore = useGlobalStore();
const isLoading = ref(false);

const store_id = computed (function () {
    return route.params.id;
});

const storeStores = useStoreStore();

const { webhook } = storeToRefs(storeStores);

const props = defineProps({
    productsList:{
        type: Object,
    }
})

const isLoaded = ref(false)

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'storeWebhookEdit'){
        if(storeStore.page.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryWebhook(route.params.slug, store_id.value).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeWebhookCreate'){

        globalStore.cleanObject(storeStore.webhook);

        globalStore.loader('hide')
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')

        if(route.name == 'storeWebhookEdit'){

            let params = ['title', 'url', 'id_product', 'events'];

            storeStore.tryUpdateWebhook(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryWebhooks().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/webhooks')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        } else if(route.name == 'storeWebhookCreate') {

            let params = ['title', 'url', 'id_product', 'events'];

            storeStore.tryCreateWebhook(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryWebhooks().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/webhooks')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}


</script>