<template>
    <template v-if="order.id">
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose" :displayGrid="'display:grid;'">
                <header>
                    <span class="me-2 align-middle">Pedido Nº {{ order.id }}</span>
                    <BaseBadget class="align-middle" :text="order.status.name" :color="order.status.color" :icon="order.status.icon" />
                </header>

                <div class="content">
                <ViewPayment :payment="order.payment" :store="order.store" />

                <ViewClient :client="order.client" />

                <ViewReferences v-if="order.references" :references="order.references" />

                <ViewTables :products="order.products" :tables_values="order.table_values" :comissions="order.comissions" />

                <div v-if="order.ordered_as_productor == true" style="max-width: 240px; margin: 0 auto" class="mt-3">
                    <baseButton class="btn-primary w-100" link :to="'/orders/' + order.id">Mais detalhes</baseButton>
                </div>
                </div>
        </BasePopup>
    </template  >
</template>

<script setup>
import ViewPayment from '@/views/orders/show/OrderShowPayment';
import ViewClient from '@/views/orders/show/OrderShowClient';
import ViewReferences from '@/views/orders/show/OrderShowReference';
import ViewTables from '@/views/orders/show/OrderShowTable';
import { useOrderStore } from '@/stores/order';
import { useGlobalStore } from '@/stores/global';

import { computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
const route = useRoute();
const router = useRouter();

const order_id = computed (function () {
    return route.params.id_order;
});

let previousPage = router.options.history.state.back;

const globalStore = useGlobalStore();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

function clickToClose() {
    if(previousPage !== null){
        if(previousPage.split('/').length > 2){
            previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
            previousPage = previousPage.substring(0, previousPage.lastIndexOf("/"));
            router.push(previousPage)
        } else {
            router.push(previousPage)
        }
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        previousPage = previousPage.substring(0, previousPage.lastIndexOf("/"));
        router.push(previousPage)
    }
}

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('show_order')) {
        return router.push('/403');
    }

    let order_preloaded = useGlobalStore().getObjectById(orderStore.orders, order_id.value);

    if(order_preloaded) {
        orderStore.order = order_preloaded;
    }else {
        globalStore.loader('show');
        orderStore.tryOrder(order_id.value).then(() => {
            globalStore.loader('hide');
        });
    }
});

</script>