<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose" :displayGrid="'display:grid;'" >
        <template v-if="refused.id">
            <header>
                <span class="me-2 align-middle">Pedido Nº {{ refused.id }}</span>
                <BaseBadget class="align-middle" :text="refused.status.name" :color="refused.status.color" :icon="refused.status.icon" />
            </header>
            <div class="content">
                <ViewPayment :payment="refused.payment" :store="refused.store" />

                <ViewClient :client="refused.client" :isProductor="refused.ordered_as_productor" />

                <ViewReferences v-if="refused.references" :references="refused.references" />

                <ViewTables :products="refused.products" :tables_values="refused.table_values" :comissions="refused.comissions" />

                <div v-if="refused.ordered_as_productor == true" style="max-width: 240px; margin: 0 auto" class="mt-3">
                    <baseButton class="btn-primary w-100" link :to="'/orders/' + refused_id">Mais detalhes</baseButton>
                </div>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import ViewPayment from '@/views/recovery/refusedorders/show/RecoveryRefusedOrderShowPayment';
import ViewClient from '@/views/recovery/refusedorders/show/RecoveryRefusedOrderShowClient';
import ViewReferences from '@/views/recovery/refusedorders/show/RecoveryRefusedOrderShowReference';
import ViewTables from '@/views/recovery/refusedorders/show/RecoveryRefusedOrderShowTable';

const route = useRoute();
const router = useRouter();

const refused_id = computed(function () {
    return route.params.id;
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useRefusedStore } from '@/stores/refused';
const refusedStore = useRefusedStore();
const { refused } = storeToRefs(refusedStore);

let previousPage = router.options.history.state.back;

onBeforeMount(async () => {
    const keyOnRefusedsList = refused.value.key;
    if (keyOnRefusedsList === undefined) {
        globalStore.loader('show');
        refusedStore.tryRefuseds(refused_id.value).then(() => {
            globalStore.loader('hide');
        });
    }else {
        refused.value.key = keyOnRefusedsList;
        refusedStore.tryRefuseds(refused.value.id).then(() => {
            refused.value[keyOnRefusedsList] = refused.value;
        });
    }
});

function clickToClose() {
    if(previousPage !== null){
        if(previousPage.split('/')[1] == 'orders' ){
            previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
            previousPage = previousPage.substring(0, previousPage.lastIndexOf("/"));
            router.push(previousPage)
        } else{
            router.push(previousPage)
        }
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        previousPage = previousPage.substring(0, previousPage.lastIndexOf("/"));
        router.push(previousPage)
    }
}

</script>