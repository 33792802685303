<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <header>
            <font-awesome-icon icon="fa-solid  fa-user-vneck" style="color: #215eda;"/>
            <span class="ms-2 align-middle">Novo convite de coprodutor</span>
        </header>
        <div class="content">
            <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        class="col-12"
                        label="Email *"
                        id="email"
                        :required="true"
                        v-model="params.email"
                        :page="page" 
                    />
                </div>
                <BaseButton @click="submit" class="mt-4 btn-primary w-100" :disabled="isLoading">
                    Salvar
                </BaseButton>
            </form>
        </div>
    </BasePopup>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { useCoproductorStore } from '@/stores/coproductor';
import { useGlobalStore } from '@/stores/global';
import { useRoute, useRouter } from 'vue-router';

const coproductorStore = useCoproductorStore();

const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();
let previousPage = router.options.history.state.back;

const page = ref('createInvitation');

const params = ref({
    email: '',
});

const isLoading = ref(false);
function submit(){
    if(isLoading.value) {
        return;
    }
    isLoading.value = true;

    if(!params.value.email) {
        globalStore.localErrors('email', 'Campo obrigatório!', page.value);
        isLoading.value = false;
        return;
    }

    globalStore.loader('show')
    coproductorStore.tryCreateInvitation(params.value).then(() =>{
        globalStore.loader('hide')
        isLoading.value = false;
    })
}

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('coproductor_invitation')) {
        return router.push('/403');
    }
});

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

</script>