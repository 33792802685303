<template>
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-5" id="content">
            <AuthBase :title="header.title" :subtitle="header.subtitle">
                <component :is="loadedComponent" @code_required="changeComponent"/>
            </AuthBase>
        </div>
        <div class="col-7" id="background">
        </div>
    </div>
</template>
<script setup>
import AuthBase from "@/components/includes/auth/AuthBase.vue";
import TheLogin from "@/components/includes/auth/login/TheLogin.vue";
import TheLoginCode from "@/components/includes/auth/login/TheLoginCode.vue";
import { ref, shallowRef } from "vue";

const header = ref({
    title: "Seja bem vindo",
    subtitle: "Digite seus dados para entrar.",
});

const loadedComponent = shallowRef(TheLogin);

function changeComponent() {
    header.value = {
        title: "Digite o código",
        subtitle: "Um código de 8 dígitos foi enviado para o seu e-mail.",
    };
    loadedComponent.value = TheLoginCode;
}
</script>

<style scoped>
#background {
    background: url(@/assets/images/background_login.jpg) no-repeat;
    background-size: cover;
    background-position: center left;
    height: 100%;
}

#content {
    background-color: #fff;
    height: 100%;
    padding-left: 120px;
}

@media screen and (max-width: 1100px){
    #content {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        background-color: #f7f7f7;
    }

    #background {
        display: none;
    }
}
</style>