<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="isLoaded != false">
            <header>
                <font-awesome-icon icon="fa-solid fa-file" style="color: #215eda;"/>
                <span v-if="route.name == 'productCoproductorEdit'" class="ms-2 align-middle">Editar coprodutor</span>
                <span v-else class="ms-2 align-middle">Novo coprodutor</span>
            </header>
            <div class="content">
            <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        v-if="route.name == 'productCoproductorEdit'"
                        class="col-12 mb-3"
                        id="id"
                        label="Coprodutor *"
                        v-model="coproductor.name"
                        :page="route.name"
                        disabled
                    />
                    <BaseSelect
                        v-else
                        class="col-12 mb-3"
                        id="id_coproductor"
                        label="Coprodutor *"
                        :items="coproductorList"
                        v-model="coproductor.id_coproductor"
                        :page="route.name"
                    />
                    <BaseSelect
                        class="col-md-6 col-12"
                        id="comission_fixed"
                        :items="type"
                        label="Tipo de comissão *"
                        placeholder="Selecione"
                        :page="route.name"
                        v-model="coproductor.comission_fixed"
                    />
                    <BaseInput
                        class="col-md-6 col-12"
                        id="comission"
                        label="Comissão *"
                        type="text"
                        :tag="(coproductor.comission_fixed == 1) ? 'R$' : '%'"
                        v-model="coproductor.comission"
                        :page="route.name"
                        :mask="(coproductor.comission_fixed == 1) ? '9.99#,##' : '9.99'"
                        :tokens="'9:[0-9]:repeated'"
                        :reversed="true"
                        :maxlength="(coproductor.comission_fixed == 1) ? 9 : 5"
                    />
                </div>
                <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                    Salvar
                </BaseButton>
            </form>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from "vue-router"; 
import { storeToRefs } from 'pinia'
import { useProductStore } from "@/stores/products";
import { useCoproductorStore } from '@/stores/coproductor'
import { useGlobalStore } from '@/stores/global'

const productStore = useProductStore();
const { coproductor }  = storeToRefs(productStore); 

const coproductorStore = useCoproductorStore();

const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

let previousPage = router.options.history.state.back;

const isLoaded = ref(false);
const isLoading = ref(false);

const type = ref({
    0: 'Porcentagem',
    1: 'Valor fixo'
});

const product_id = computed (function () {
    return route.params.id;
});

const coproductorList = ref({})

onBeforeMount(() =>{
    if(route.name == 'productCoproductorEdit'){
        if(productStore.coproductor.id == undefined){
            globalStore.loader('show')
            productStore.tryCoproductor(route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true
            })
        } else {
            isLoaded.value = true
        }
    } else if(route.name =='productCoproductorCreate'){
        isLoaded.value = true
        globalStore.cleanObject(productStore.coproductor);
        globalStore.loader('show')
        coproductorStore.tryCoproductors().then( function(success) {
            if(success !== false){
                for(let i = 0; i < coproductorStore.coproductors.length; i++){
                    coproductorList.value[coproductorStore.coproductors[i].id] = coproductorStore.coproductors[i].name
                    globalStore.loader('hide')
                }
            }
        })
    }
})

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.name == 'productCoproductorEdit'){
            let params = ['id', 'comission', 'comission_fixed'];

            productStore.tryUpdateCoproductor(params, route.name,  productStore.coproductor.id).then(function(success) {
                if(success){
                    isLoading.value = false;
                    productStore.tryCoproductors().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/coproductor')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                } else {
                    globalStore.loader('hide');
                    isLoading.value = false;
                }
            });

        } else {
            let params = ['id', 'comission', 'comission_fixed'];
            
            productStore.tryCreateCoproductor(params, route.name).then(function(success){
                if(success){
                    isLoading.value = false;
                    productStore.tryCoproductors().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/coproductor')
                        } else{
                            globalStore.loader('hide')
                        }
                    });
                }else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            })
        }
    }
}

</script>