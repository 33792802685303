<template>
    <div class="form-group datepicker_component" :id="id">
        <label v-if="label" :class="[error ? 'danger-label' : '']">{{ label }}</label>
        <span v-if="circle_background" class="circle" :style="'background:' + '#' + circle_background"></span>
        <span v-if="route.name == 'home' || route.name == 'view-referral-report' || route.name == 'view-recommendations-report' || route.name == 'reports-insights'" id="size-calibration">{{ initialMonthYear}}</span>
        <DatePicker
            v-model="date"
            :enable-time-picker="timepicker"
            locale="pt-br"
            :hide-input-icon="hideInputIcon"
            auto-apply
            :close-on-auto-apply="close_autoapply"
            :day-names="['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa']"
            weekStart="0"
            :placeholder="placeholder"
            :format="format"
            :range="range"
            :month-picker="month"
            :start-date="startDate"
            :input-class-name="error ? 'danger-border' : ''"
            @update:model-value="deleteError"
            @update-month-year="firstSelection"
            id="datepickerid"
            ref="datePickerRef"
        >
            <template #arrow-left>
                <font-awesome-icon class="slot-icon" icon="far fa-chevron-left" />
            </template>

            <template #arrow-right>
                <font-awesome-icon class="slot-icon" icon="far fa-chevron-right" />
            </template>
            
            <template #input-icon>
                <font-awesome-icon icon="fa-solid fa-chevron-down"/>
            </template>

            <template #clear-icon="{  }">
                <span @click="clearDate()" class="input_clear"><font-awesome-icon icon="fa-solid fa-xmark"/></span>
            </template>
            

            <template #left-sidebar="{ }" v-if="props.enablePreset === true">
                <div class="dp__preset_ranges">
                    <div class="dp__preset_range" id="presetToday" @click="setPreset('today', $event)">Hoje <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                    <div class="dp__preset_range" id="presetYesterday" @click="setPreset('yesterday', $event)">Ontem <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                    <div class="dp__preset_range" id="presetSeven" @click="setPreset('lastseven', $event)">Últimos 7 dias <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                    <div class="dp__preset_range" id="presetFifteen" @click="setPreset('lastfifteen', $event)">Últimos 15 dias <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                    <div class="dp__preset_range" id="presetThisMonth" @click="setPreset('thismonth', $event)">Este mês <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                    <div class="dp__preset_range" id="presetLastMonth" @click="setPreset('lastmonth', $event)">Último mês <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                    <div class="dp__preset_range" id="presetAll" @click="setPreset('all', $event)">Tudo <font-awesome-icon :icon="['far', 'circle-check']" class="preset_active_icon"/></div>
                </div>
            </template>
        </DatePicker>
        <span v-if="props.enablePreset === true && presetsText !== null && date !== null" class="preset_text dp__pointer dp__input_readonly dp__input dp__input_icon_pad dp__input_reg">{{ presetsText }}</span>
        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script setup>
import { ref, watch, onBeforeMount, onMounted, computed} from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { endOfMonth, startOfMonth, subMonths, startOfYesterday, sub} from 'date-fns';
import { useGlobalStore } from '@/stores/global';
import { useRoute } from 'vue-router';

const route = useRoute();

const globalStore = useGlobalStore();

const date = ref(null);
const startDate = ref(null)
const firstSelect = ref(false)
const rawDate = ref(null);

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: null,
    },

    required: {
        type: Boolean,
        required: false,
        default: null,
    },

    id: {
        type: String
    },

    page:{
        type: String,
    },

    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },

    type: {
        type: String,
        required: false,
        default: 'day'
    },
    
    hideInputIcon: {
        type: Boolean,
        default: true,
    },

    multi: {
        type: Boolean,
        required: false,
        default: null,
    },

    range: {
        type: Boolean,
        required: false,
        default: null
    },

    close_autoapply:{
        type: Boolean,
        required:false,
        default:true,
    },

    enablePreset:{
        type: Boolean,
        required: false,
        default: false,
    },

    month: {
        type: Boolean,
        required: false,
        default: null
    },
    
    timepicker: {
        type: Boolean,
        required: false,
        default: null,
    },

    circle_background: {
        type: String,
        required: false,
        default: null,
    },

    placeholder: {
        type:String,
        default: "Selecione",
    },

    modelValue: {
    },

    ajustableContentWidth: {
        type: Boolean,
    },

    secondLoadChart:{

    },

    storeLoaded: {
        type: Boolean,
        default: false,
    },

    loadedDayPicker: {
        type: Boolean,
        default: false,
    },

    loadedThisMonthPicker:{
        type: Boolean,
        default: false,
    },

    loadedLastMonthPicker:{
        type: Boolean,
        default: false,
    }

});

const scndLoadChart = ref(null)
const datePickerRef = ref();
var initialMonthYear = ref(null)
var presetsText = null;
var typableType = null;

const presetParams = {
    personalizedDate: null,
}


let disabledEmit = true;

onBeforeMount(() => {
    if(props.modelValue) {
        let value = props.modelValue;
        if(props.secondLoadChart){
            scndLoadChart.value = props.secondLoadChart;
        }
        if(props.multi === null && props.month === null && props.range === null) {

            let selectedDate = value.split('-');

            for(var d=0; d <= 2 ; d++) {
                    if(d > 0) {
                        for(let i = 0; i < selectedDate[d].length; i++){
                            if(selectedDate[d][i] === 0){
                                selectedDate[d] = selectedDate[d].replace('0', '');
                            }
                        }
                    }
                }
                
            date.value = String(selectedDate[0]) + '/' + String(selectedDate[1]) + '/' + String(selectedDate[2]);

            disabledEmit = false;

        }else {
            if(props.month === null && props.timepicker === null) {
                if(value && value.length !== 0){
                    let dateSplit = value.split('@');

                    let dateStart = dateSplit[0].split('-');
                    let dateEnd = dateSplit[1].split('-');

                    for(var dz=0; dz <= 2 ; dz++) {
                        if(dz > 0) {
                            if(dateStart[dz] < 10){
                                dateStart[dz] = dateStart[dz].replace('0', '');
                            }
                            if(dateEnd[dz] < 10){
                                dateEnd[dz] = dateEnd[dz].replace('0', '');
                            }
                        }
                    }
                    date.value = [String(dateStart[0]) + '-' + String(dateStart[1]) + '-' + String(dateStart[2]), String(dateEnd[0]) + '-' + String(dateEnd[1]) + '-' + String(dateEnd[2])];
                    disabledEmit = false;
                }

            }
            else if (props.timepicker != null){
                let dateSplit = value.split('@');
                
                let dateStart = dateSplit[0].split('-');
                let dateEnd = dateSplit[1].split('-');

                date.value = [String(dateStart[0]) + '-' + String(dateStart[1]) + '-' + String(dateStart[2]), String(dateEnd[0]) + '-' + String(dateEnd[1]) + '-' + String(dateEnd[2])];
                disabledEmit = false;
            } else {
                if(value){
                    let selectedDate = value.split('-');
                    if(parseInt(selectedDate[1]) < 10){
                        selectedDate[1] = selectedDate[1].replace('0', '');
                    }
                
                    date.value = {
                        month: String(selectedDate[1]),
                        year: String(selectedDate[0])
                    };

                    disabledEmit = false;
                }
            }
        }
    }else {
        disabledEmit = false;
    }
});

const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

function format(date) {
    rawDate.value = date;
    if(props.multi === null && props.month === null && props.range === null && props.timepicker === null) {
        const day = addZero(date.getDate());
        const month = addZero(date.getMonth() + 1);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;

    }else {
        
        if(props.month === null && props.timepicker === null) {
            const day1 = addZero(date[0].getDate());
            const month1 = addZero(date[0].getMonth() + 1);
            const year1 = date[0].getFullYear();

            const day2 = addZero(date[1].getDate());
            const month2 = addZero(date[1].getMonth() + 1);
            const year2 = date[1].getFullYear();

            return `${day1}/${month1}/${year1}  - ${day2}/${month2}/${year2}`;
        }else if (props.timepicker != null){
            const day1 = addZero(date[0].getDate());
            const month1 = addZero(date[0].getMonth() + 1);
            const year1 = date[0].getFullYear();
            const hour1 = addZero(date[0].getHours());
            const minute1 = addZero(date[0].getMinutes());

            const day2 = addZero(date[1].getDate());
            const month2 = addZero(date[1].getMonth() + 1);
            const year2 = date[1].getFullYear();
            const hour2 = addZero(date[1].getHours());
            const minute2 = addZero(date[1].getMinutes());

            return `${day1}/${month1}/${year1} ${hour1}:${minute1} - ${day2}/${month2}/${year2} ${hour2}:${minute2}`;
        }else {
            let month = date.getMonth();
            let year = date.getFullYear();

            let monthInitial = date.getMonth() - 1;
            initialMonthYear.value =  `${monthNames[monthInitial]} ${year}`;
            if(scndLoadChart.value == false || firstSelect.value == false){
                let newDate = new Date(date.setMonth(date.getMonth()- 1))
            
                if(monthInitial === -1){
                    date.setFullYear(date.getFullYear() + 1);
                    monthInitial = 11;
                    month = 12;
                }
                
                startDate.value = newDate;
                return `${monthNames[month - 1]} ${year}`;
            } else {
                return `${monthNames[month]} ${year}`
            }

        }
    }
}

function addZero(r) {
    return (Number(r) < 10) ? '0' + r  : r;
}

const emit = defineEmits(["update:modelValue", "updateInfos" , "clearDayPicker","formatMonthEvent", "loadThisInfos", "loadLastInfos"]);

function clearDate() {
    lastEmitedDate.value = null;
    date.value = null;
    presetsText = null;
}

function firstSelection(){
    if(firstSelect.value == true){
        return;
    } else {
        firstSelect.value = true;
    }
}

const lastEmitedDate = ref(null);

watch(date, function () {
    if(date.value == null){
        emit("update:modelValue", null);
    }
});

watch(rawDate, function (e) {
    if(e && disabledEmit == false) {

        if(props.multi === null && props.month === null && props.range === null && props.timepicker === null) {
            let current = e.getFullYear() + '-' + (parseInt(e.getMonth()) + 1) + '-' + e.getDate();
            if(current !== lastEmitedDate.value) {
                lastEmitedDate.value = current;
                emit("update:modelValue", current);
            }

        }else{

            if(props.month === null && props.timepicker === null) {
                
                let date_start = e[0].getFullYear() + '-' + addZero(parseInt(e[0].getMonth()) + 1) + '-' + addZero(e[0].getDate());
                let date_end  =e[1].getFullYear() + '-' + addZero(parseInt(e[1].getMonth()) + 1) + '-' + addZero(e[1].getDate());
                let current = [date_start, date_end];
                if(lastEmitedDate.value != null){
                    if(current[0] !== lastEmitedDate.value[0] || current[1] !== lastEmitedDate.value[1]) {
                        lastEmitedDate.value = current;
                        emit("update:modelValue", current);
                        
                    }
                }else{
                    lastEmitedDate.value = current;
                    emit("update:modelValue", current);
                }

                presetParams.personalizedDate = addZero(e[0].getDate())  + '-' + addZero(parseInt(e[0].getMonth()) + 1) + '-' + e[0].getFullYear() + addZero(e[1].getDate())  + '-' + addZero(parseInt(e[1].getMonth()) + 1) + '-' + e[1].getFullYear();
            } else if(props.timepicker != null){
                let date_start = e[0].getFullYear() + '-' + addZero(parseInt(e[0].getMonth()) + 1) + '-' + addZero(e[0].getDate() + ' ' + addZero(parseInt(e[0].getHours())) + ':' + addZero(parseInt(e[0].getMinutes())))
                let date_end  =e[1].getFullYear() + '-' + addZero(parseInt(e[1].getMonth()) + 1) + '-' + addZero(e[1].getDate()) + ' ' + addZero(parseInt(e[1].getHours())) + ':' + addZero(parseInt(e[1].getMinutes()));
                let current = [date_start, date_end];
                if(current !== lastEmitedDate.value) {
                    lastEmitedDate.value = current;
                    emit("update:modelValue", current);
                    
                }   
            }else {
                let current = e.getFullYear() + '-' + addZero(parseInt(e.getMonth()) + 1);
                if(current !== lastEmitedDate.value) {
                    lastEmitedDate.value = current;
                    emit("update:modelValue", e.getFullYear() + '-' + addZero(parseInt(e.getMonth()) + 1));
                }
            }
        }
    }   
});

onMounted(() => {
    if(route.name == 'home' || route.name == 'view-referral-report' || route.name == 'view-recommendations-report' || route.name == 'reports-insights' && props.storeLoaded === true){
        if(props.loadedDayPicker === true){
            var inputDayPicker = document.querySelector(".metrics_amount_picker .dp__input_wrap");
            inputDayPicker.addEventListener("click", addDayListeners);
        }

        if(props.loadedThisMonthPicker === true){
            var inputThisMonth = document.querySelector("#FirstSalesDatepicker .dp__input_wrap");
            inputThisMonth.addEventListener("click", addThistMonthListeners);
        }

        if(props.loadedLastMonthPicker === true){
            var inputLastMonth = document.querySelector("#SecondSalesDatepicker .dp__input_wrap");
            inputLastMonth.addEventListener("click", addLastMonthListeners);
        }
    }

    if(props.enablePreset === true){
        let inputPicker = document.querySelectorAll(".preset_picker .dp__input_wrap");
        for(let i = 0; i < inputPicker.length; i++){
            inputPicker[i].addEventListener("click", (e) =>{
                setTimeout(() =>{
                    selectActivePreset(e);
                    addDayListeners()
                }, 10)
            })
        }
    }
    
    if(props.id){
        document.querySelector('#datepickerid input').setAttribute("id", props.id)
    }
})

function addDayListeners(){
    setTimeout(() => {
        const calendarBox = document.querySelector(".dp__menu_content_wrapper")
        if(calendarBox){
            calendarBox.addEventListener('mouseover', () => {
                const inputDayCell = document.querySelectorAll(".dp__cell_inner")
                for (let index = 0; index < inputDayCell.length; index++){
                    inputDayCell[index].addEventListener("click", emitInfosDayCell);
                }
                const inputPresets = document.querySelectorAll(".dp__preset_range");
                for (let index = 0; index < inputPresets.length; index++){
                    inputPresets[index].addEventListener("click", emitInfos);
                }
            });
        }
    }, 10);
}

function emitInfos(){
    setTimeout(() => {
        emit('updateInfos')
    }, 10)
}

function emitInfosDayCell(){
    setTimeout(() => {
        emit('updateInfos')
        if(rawDate.value){
            presetsText = addZero(rawDate.value[0].getDate()) + '/' + addZero(rawDate.value[0].getMonth() + 1) + '/' + rawDate.value[0].getFullYear() + ' - ' + addZero(rawDate.value[1].getDate()) + '/' + addZero(rawDate.value[1].getMonth() + 1) + '/' + rawDate.value[1].getFullYear();
        }
    }, 10)

}

function addThistMonthListeners (){
    setTimeout(() => {
        const inputMonthCell = document.querySelectorAll(".dp__overlay_col")
        for (let index = 0; index < inputMonthCell.length; index++){
            inputMonthCell[index].addEventListener("click", clearThisMonthIcon);
        }
        scndLoadChart.value = true;
    }, 10);
}

function clearThisMonthIcon(){
    setTimeout(() => {
        var rawInput = document.querySelector("#FirstSalesDatepicker .dp__input_wrap input");
        var spansize = document.querySelector("#FirstSalesDatepicker #size-calibration")
        spansize.innerText = rawInput.value;

        const clearIcon = document.querySelector('#FirstSalesDatepicker .input_clear')
        clearIcon.addEventListener("click", () => {
            setTimeout(() => {
                emit('loadThisInfos')
            },10);
        });
    },);
    emit('formatMonthEvent')
}

function addLastMonthListeners (){
    setTimeout(() => {
        const inputMonthCell = document.querySelectorAll(".dp__overlay_col")
        for (let i = 0; i < inputMonthCell.length; i++){
            inputMonthCell[i].addEventListener("click", clearLastMonthIcon);
        }
        scndLoadChart.value = true;
    }, 10);
}

function clearLastMonthIcon(){
    setTimeout(() => {
        let rawInput = document.querySelector("#SecondSalesDatepicker .dp__input_wrap input");
        var spansize = document.querySelector("#SecondSalesDatepicker #size-calibration")

        spansize.innerText = rawInput.value;

        const clearIcon = document.querySelector('#SecondSalesDatepicker  .input_clear')
        clearIcon.addEventListener("click", () => {
            setTimeout(() => {
                emit('loadLastInfos')
            },10);
        });
        emit('formatMonthEvent')
    },);
}

function resizeDayPicker(){
    var inputIcon = document.querySelector(".metrics_amount_picker .dp__input_icon");
    var inputDayPicker = document.querySelector(".metrics_amount_picker .dp__input_wrap");
    var rawInput = document.querySelector("#DayPicker .dp__input_wrap input");
    var spansize = document.querySelector("#DayPicker #size-calibration");

    inputIcon.style.display= 'none';

    if(presetsText){
        spansize.innerText = presetsText;
        inputDayPicker.style.width = (spansize.offsetWidth + 45) + 'px';
    }else{
        spansize.innerText = rawInput.value;
        inputDayPicker.style.width = 215 + 'px';
    }

    setTimeout(() => {
        const clearIcon = document.querySelector('#DayPicker .input_clear')
        clearIcon.addEventListener("click", () => {
            setTimeout(() => {
                emit('clearDayPicker')
                emit("update:modelValue", null)
                inputIcon.style.display= 'block';
            },10);
        });
    },);
}

function setPreset(type){
    const todayDate = new Date();

    rawDate.value = []
    date.value = []
    if(type === 'today'){
        rawDate.value.push(todayDate);
        rawDate.value.push(todayDate);
        date.value.push(todayDate)
        date.value.push(todayDate)
        presetsText = 'Hoje'
    } else if(type === 'yesterday'){
        rawDate.value.push(startOfYesterday(todayDate))
        rawDate.value.push(startOfYesterday(todayDate))
        date.value.push(startOfYesterday(todayDate))
        date.value.push(startOfYesterday(todayDate))
        presetsText = 'Ontem'
    } else if(type === 'lastseven'){
        rawDate.value.push(sub(new Date(), {days: 7,}))
        rawDate.value.push(todayDate)
        date.value.push(sub(new Date(), {days: 7,}))
        date.value.push(todayDate)
        presetsText = 'Últimos 7 dias'
    } else if(type === 'lastfifteen'){
        rawDate.value.push(sub(new Date(), {days: 15,}))
        rawDate.value.push(todayDate)
        date.value.push(sub(new Date(), {days: 15,}))
        date.value.push(todayDate)
        emit("update:modelValue", 'Últimos 7 dias');
        presetsText = 'Últimos 15 dias'
    }  else if(type === 'thismonth'){
        rawDate.value.push(startOfMonth(todayDate))
        rawDate.value.push(endOfMonth(todayDate))
        date.value.push(startOfMonth(todayDate))
        date.value.push(endOfMonth(todayDate))
        presetsText = 'Este mês'
    } else if(type === 'lastmonth'){
        rawDate.value.push(startOfMonth(subMonths(todayDate, 1)))
        rawDate.value.push(endOfMonth(subMonths(todayDate, 1)))
        date.value.push(startOfMonth(subMonths(todayDate, 1)))
        date.value.push(endOfMonth(subMonths(todayDate, 1)))
        presetsText = 'Último mês'
    } else if(type === 'all'){
        let startAll = new Date('2021-01-01')
        rawDate.value.push(startAll);
        rawDate.value.push(todayDate);
        date.value.push(startAll)
        date.value.push(todayDate)
        presetsText = 'Tudo'
    }
    typableType = null;
    datePickerRef.value.closeMenu();
}

const error = computed(() =>{
    if(globalStore.errors[props.page] && globalStore.errors[props.page][props.id]) {
        return globalStore.errors[props.page][props.id];
    }
    return null; 
})

function deleteError(){
    if(globalStore.errors[props.page] && globalStore.errors[props.page][props.id]) {
        delete globalStore.errors[props.page][props.id];
    }
}

function selectActivePreset(e){
    let todayDate = new Date();
    let lastSeven = sub(new Date(), {days: 7,})
    let lastFifteen = sub(new Date(), {days: 15,})
    let thisMonth = [startOfMonth(todayDate), endOfMonth(todayDate)];
    let lastMonth = [startOfMonth(subMonths(todayDate, 1)), endOfMonth(subMonths(todayDate, 1))];

    let todayDateStrings = addZero(todayDate.getDate()) + '/' + addZero(todayDate.getMonth()+ 1) + '/' + todayDate.getFullYear() + '  - ' + addZero(todayDate.getDate()) + '/' + addZero(todayDate.getMonth() + 1) + '/' + todayDate.getFullYear();
    let yesterdayDateStrings = addZero((todayDate.getDate() - 1)) + '/' + addZero(todayDate.getMonth() + 1) + '/' + todayDate.getFullYear() + '  - ' + addZero(todayDate.getDate() - 1) + '/' + addZero(todayDate.getMonth() + 1) + '/' + todayDate.getFullYear();
    let lastSevenDateStrings = addZero(lastSeven.getDate()) + '/' + addZero(lastSeven.getMonth() + 1) + '/' + lastSeven.getFullYear() + '  - ' + addZero(todayDate.getDate()) + '/' + addZero(todayDate.getMonth() + 1) + '/' + todayDate.getFullYear();
    let lastFifteenDateStrings =  addZero(lastFifteen.getDate()) + '/' + addZero(lastFifteen.getMonth() + 1) + '/' + lastFifteen.getFullYear() + '  - ' + addZero(todayDate.getDate()) + '/' + addZero(todayDate.getMonth() + 1) + '/' + todayDate.getFullYear();
    let thisMonthDateStrings = addZero(thisMonth[0].getDate()) + '/' + addZero(thisMonth[0].getMonth() + 1) + '/' + thisMonth[0].getFullYear() + '  - ' + addZero(thisMonth[1].getDate()) + '/' + addZero(thisMonth[1].getMonth() + 1) + '/' + thisMonth[1].getFullYear();
    let lastMonthDateStrings = addZero(lastMonth[0].getDate()) + '/' + addZero(lastMonth[0].getMonth() + 1) + '/' + lastMonth[0].getFullYear() + '  - ' + addZero(lastMonth[1].getDate()) + '/' + addZero(lastMonth[1].getMonth() + 1) + '/' + lastMonth[1].getFullYear();
    let allDateStrings = '31/12/2020' + '  - ' + addZero(todayDate.getDate()) + '/' + addZero(todayDate.getMonth() + 1) + '/' + todayDate.getFullYear();

    if(e.target.value){

        if(typableType === 'personalized'){
            document.getElementById('headingThree').classList.add('active_preset')
        }else if(e.target.value === todayDateStrings && document.getElementById('presetToday')){
            document.getElementById('presetToday').classList.add('active_preset')
        } else if(e.target.value === yesterdayDateStrings && document.getElementById('presetYesterday')){
            document.getElementById('presetYesterday').classList.add('active_preset')
        }else if(e.target.value === lastSevenDateStrings && document.getElementById('presetSeven')){
            document.getElementById('presetSeven').classList.add('active_preset')
        } else if(e.target.value === lastFifteenDateStrings && document.getElementById('presetFifteen')){
            document.getElementById('presetFifteen').classList.add('active_preset')
        }else if(e.target.value === thisMonthDateStrings && document.getElementById('presetThisMonth')){
            document.getElementById('presetThisMonth').classList.add('active_preset')
        }else if(e.target.value === lastMonthDateStrings && document.getElementById('presetLastMonth')){
            document.getElementById('presetLastMonth').classList.add('active_preset')
        } else if (e.target.value === allDateStrings && document.getElementById('presetAll')){
            document.getElementById('presetAll').classList.add('active_preset')
        } else {
            if(e.target.value !== null ){
                let valueSplited = e.target.value.split('-');

                let firstValue = valueSplited[0].replace('  ', '');
                let secondValue = valueSplited[1].replace(' ', '')

                if(firstValue && secondValue && document.getElementById('headingThree')){
                    document.getElementById('headingThree').classList.add('active_preset');
                }
            }
        }
    }

}

defineExpose({
    resizeDayPicker,
})

</script>

<style>

.datepicker_component{
    height: 39px;
}

label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 500!important;
    color: #545454;
}

#size-calibration {
    visibility: hidden;
    white-space: pre;
    margin-left: 52px;
  }

.dp__menu{
    --bs-dropdown-zindex: 1000; 
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-width: var(--bs-border-width);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow-x: hidden;
}


.dp__menu,
.dp__calendar_wrap,
.dp__input 
{
    font-family: "Poppins", "Roboto", sans-serif !important;
    font-size: 14px!important; 
}

.dp__calendar_header_separator{
    display: none;
}

.dp__input {
    border-radius: 6px;
    padding: 6px 10px ;
    height: 39px;
    border: 1px solid #d7d7d7;
    color: #495057 !important;
}

.dp__input::placeholder {
    color: rgba(0, 0, 0, 2);
    font-weight: 400;
    font-size: 14px;
}

.dp__input:hover {
    border: 1px solid #d7d7d7;
}

.dp__input.dp__input_focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 8%) !important;
    border: 1px solid #b8b8b8 !important;
    outline: none !important;
}

.dp__input_icon {
    left: 70%!important;
}

.danger-border{
    border: 1px solid #fd6d6d!important;
    box-shadow: 0px 0px 0px 3px #ffe7e7 !important
}

.danger-label {
    color: #d60e0e!important;
}

.danger-border::-webkit-input-placeholder {
  color: #d60e0e;
}

.danger-border:-moz-placeholder{
  color: #d60e0e;
}

.danger-border::-moz-placeholder{
  color: #d60e0e;
}

.danger-border:-ms-input-placeholder {
  color: #d60e0e;
}

.dp__calendar_next .dp__calendar{
    border-left: 1px solid #d7d7d7;
    margin-bottom: 10px;
    margin-left: -21px;
}

.dp__instance_calendar {
    padding: 7px;
}

.slot-icon {
    height: 11px !important;
    width: 11px !important;
    color: #262626 !important;
}

.dp__arrow_top, .dp__arrow_bottom{
    display:none!important;
}

.dp__input_wrap {
    max-height: 39px;
}

.dp__overlay_col, .dp__month_year_select {
    text-transform: capitalize;
}

.dp__clear_icon {
    margin-top: 6px;
    width: 35px;
    height: 35px;
    z-index: 9;
}

.input_clear {
    color: #215eda !important;
    padding: 5px 9px;
    border-radius: 50px;
    font-size: 12px;
    margin-left: 5px;
}

.input_clear:hover {
    box-shadow: inset 0px 0px 50px rgb(0 0 0 / 20%);
}

.dp__range_end, .dp__range_start, .dp__active_date {
    background: #215eda;
    color: var(--dp-primary-text-color);
}

.circle {
    width: 10px!important;
    height: 10px!important;
    border-radius: 10px!important;
    display: inline-block;
    margin-left: 5px;
}

#personalizedDate{
    text-align: center;
}

#collapseThree{
    padding: 5px; 
    height: 50px
}

.dp__sidebar_left{
    padding: 5px 0px;
}

.dp__preset_ranges{
    width: 260px;
    border-right: 0px;
    padding: 5px 0px;
}

.dp__preset_range{
    padding: 10px 15px;
}

.dp__preset_range:hover{
    background-color: #f8f9fa;
}

 #specificDate{
    text-align: center;
}


.active_preset{
    color: #215eda;
    background-color: #f8f9fa !important;
}

.active_preset .preset_active_icon{
    display: block;
} 

.preset_active_icon{
    margin: 3px;
    float: right;
    display: none;
}

.preset_text{
    display: block;
    position: relative;
    top: -39px;
    left: 0px;
    background-color: white;
    pointer-events: none;
}

.box_search{
    cursor: pointer;
    color: white;
    padding: 3px;
    width: 35px;
    height: 25px;
    text-align: center;
    background-color: #215eda;
}

.box_search:hover{
    opacity: 0.95;
}

@media screen and (max-width: 992px){
    .datepicker_component{
        height: 60px!important;
    }
}

@media screen and (max-width: 768px){
    .dp__menu_content_wrapper{
        display: inline-block;
    }
}

@media screen and (max-width: 600px){
    
    .dp__calendar_next .dp__calendar{
        border: 0px;
    }

}


@media screen and (max-height: 875px){
    .dp__menu{
        max-height: 500px!important;
        overflow-y: auto!important;
    }
}

@media screen and (max-height: 725px){
    .dp__menu{
        max-height: 400px!important;
        overflow-y: auto!important;
    }
}

@media screen and (max-height: 660px){
    .dp__menu{
        max-height: 300px!important;
        overflow-y: auto!important;
    }
}

@media screen and (max-height: 525px){
    .dp__menu{
        max-height: 210px!important;
    }
}

</style>